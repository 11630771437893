<template>
  <section class="dashboard-header">
    <div class="container-fluid">
      <div class="card-header">
        <h2>{{msg}}</h2>
        <div></div>
      </div>

      <div id="carpeta" class="bg-white has-shadow my-3 py-4 px-4">

        <b-card
          header="Carpeta Mensual"                           
        >
          <b-card-text>

            <b-row class="">
              <b-col sm="5">
                
                <b-row class="my-2">
                  <b-col sm="4">
                    <label for="input-small" class="mt-1">Mes:</label>
                  </b-col>
                  <b-col sm="5">
                    <span class="mt-1">{{ fechaCarpeta | moment("MMMM - YYYY") }}</span>
                  </b-col>
                  <b-col sm="3">
                    <b-button-group class="mx-1">
                      <b-button size="sm" variant="light" v-on:click="mesAnt">&laquo;</b-button>
                      <b-button size="sm" variant="light" v-on:click="mesSig">&raquo;</b-button>
                    </b-button-group>
                  </b-col>
                </b-row>

                <b-row class="my-1">
                  <b-col sm="4">
                    <label for="input-small">Región:</label>
                  </b-col>
                  <b-col sm="6">
                    <b-form-select v-model="selected" :options="regiones" size="sm" class=""></b-form-select>
                  </b-col>
                </b-row>

                <b-row class="my-4">
                  <b-col sm="4">
                    <label for="input-small">Número de Carpeta:</label>
                  </b-col>
                  <b-col sm="5">
                    <b-form-input v-model="numCarpeta" size="sm" placeholder="Número de carpeta"></b-form-input>
                  </b-col>
                </b-row>

              </b-col>
              <b-col sm="7">
                
              </b-col>
            </b-row>

            
          </b-card-text>          
        </b-card>
                                  
        <b-row class="mt-3">          
          <b-col lg="2" class="pb-2"><b-button size="sm" v-on:click="descargarCarpeta">Descargar Carpeta</b-button></b-col>
          <!-- <b-col lg="3" class="pb-2"><b-form-input v-model="email" size="sm" placeholder="Correo Electrónico"></b-form-input></b-col>          
          <b-col lg="2" class="pb-2"><b-button size="sm">Enviar</b-button></b-col>           -->
        </b-row>



      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import download from 'downloadjs'

export default {
  data() {
    return {
      msg: "Carpeta",
      email: '',
      selected: this.$route.params.region,
        regiones: [
          { value: 'TODO', text: 'TODO' },
          { value: 'TSUR', text: 'REGION SUR' },
          { value: 'TNOR', text: 'REGION NORTE' },
          { value: 'TBAJ', text: 'REGION BAJIO' },
          { value: 'TPRO', text: 'PROYECTOS' },
        ],
      numCarpeta: this.$route.params.numCarpeta,
      fechaCarpeta: this.$moment(this.$route.params.fechaCarpeta),      
      xls: "",
      xlsName: 'CARPETA_MENSUAL_TEMARQSA_'
    };
  },
  
  mounted() {
   
  },

  created () {
    
  },
  methods: {
    mesAnt() {
      this.fechaCarpeta = this.$moment(this.fechaCarpeta).add(-1, 'M');
    },
    mesSig(){
      this.fechaCarpeta = this.$moment(this.fechaCarpeta).add(1, 'M');
    },
    descargarCarpeta() {
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.$cookies.get("token");
      var params = "?fechaCarpeta=" + this.fechaCarpeta.format("YYYY-MM-DD") + "&region=" + this.selected + "&numCarpeta=" + this.numCarpeta;
      axios.get(process.env.VUE_APP_ROOT_API + "api/secure/incidencias/carpeta" + params )
        .then(response => {
            this.xls = "data:application/octet-stream;charset=utf-16le;base64," + response.data;

          download(this.xls, this.xlsName + '' + this.selected.toUpperCase() + '_' + this.numCarpeta + '.xlsx' , "application/octet-stream");

            //console.log(this.xls);           
        },error => {

        }
        );
    }
  }
};
</script>

<style>
</style>